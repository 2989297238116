import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import Button from '~components/blocks/Button'
import resolveLink from '~utils/resolveLink'
// import { mobile } from '~styles/global'
import { Larr, Rarr } from '../../Svg';
import RichText from '~components/RichText'
import Slide from '~components/blocks/Slide'
import {AnimatePresence, motion} from 'framer-motion'

const SliderBlock = ({ slides, className }) => {

	const {mobile} = breakpoints
	const [currentSlide, setCurrentSlide] = useState(0)
	const [isMobile, setIsMobile] = useState(false)
	const [windowWidth, setWindowWidth] = useState(undefined)

	useEffect(() => {
		const handleResize = () => {
			const mob = window.innerWidth < 768
			const width = window.innerWidth
			setIsMobile(mob)
			setWindowWidth(width)
		}
		handleResize()
		window.addEventListener('resize', handleResize())
		return () => {	
			window.removeEventListener('resize', handleResize())
		}
	}, [])


    const prevSlide = () => {
			if (currentSlide === 0) {
				setCurrentSlide(slides.length - 1)
			} else {
				setCurrentSlide(prevState => (prevState - 1))
			}
    }
  
    const nextSlide = () => {
			if (currentSlide === slides.length - 1) {
				setCurrentSlide(0)
			} else {
				setCurrentSlide(prevState => (prevState + 1))
			}
    }

    const arrowCss= `
      transform: scale(1);
      transition: transform 0.3s;
      &:hover{
        transform: scale(1.15);
      }
    `

			const returnLink = (slide) => {
				if (slide?.link?.to){
					return(
						resolveLink(slide?.link?.to?._type, slide?.link?.to?.slug?.current, slide?.link?.to?.defaultParentCategory?.slug?.current) + (slide.queryString ? slide.queryString : '')
						)
				} else if (slide?.link?.document && slide?.link?.linkType === 'internal'){
					return(
						resolveLink(slide?.link?.document?._type, slide?.link?.document?.slug?.current, slide?.link?.document?.defaultParentCategory?.slug?.current) + (slide.queryString ? slide.queryString : '')
					)
				} else {
					return slide?.link?.url
				}
			} 

    return (
      <Section
        className={className}
      >
        <div
          css={css`
          grid-column: 1 / 4;
          grid-row: 1;
          position: relative;
          height: 100%;
          ${mobile}{
            grid-column: 1 / 13;
            grid-row: 2;
						min-height: 90px;
          }
        `}>
					<AnimatePresence exitBeforeEnter>
            <motion.div
              key={currentSlide}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
              css={css`
                display: grid;
                align-content: start;
            `}>
              <div>
                   {slides[currentSlide]._type === 'homeSlide' ?
                    <>
                      <div css={css`
                        margin-bottom: 30px;
                        ${mobile}{
                          margin-bottom: 22px;
                        }
                      `}>
                        <span
                          className="h4"
                          css={css`
                            margin-right: 10px;
                          `}
                        >
                          {slides[currentSlide]?.product?.title}
                        </span>
                        {slides[currentSlide]?.product?.content?.brand?.title}
                      </div>
											{slides[currentSlide]?.text &&
												<div css={css`margin-bottom: 30px;`}>
													<p>{slides[currentSlide]?.text}</p>
												</div>
											}
                      <Button
                        to={resolveLink(slides[currentSlide]?.product?._type, slides[currentSlide]?.product?.slug?.current)}
                        css={css`
                          margin-bottom: 10px;
                        `}
                      >
                      Shop Now
                      </Button>
                    </>
                    : 
                    <>
                      <div 
                        className="h4"
                        css={css`
                          margin-bottom: 30px;
                          ${mobile}{
                            margin-bottom: 22px;
                          }
                      `}>
                          {slides[currentSlide]?.title}
                      </div>
											{slides[currentSlide]?.text && 
												<div css={css`margin-bottom: 30px;`}>
														<RichText content={slides[currentSlide].text}/>
												</div>
											}
											{slides[currentSlide]?.link && 
                      <Button
                        to={returnLink(slides[currentSlide])}
                        css={css`
                          margin-bottom: 10px;
                        `}
                      >
                        {slides[currentSlide]?.link?.linkText ?? slides[currentSlide]?.linkText}
                      </Button>}
                    </>
                    }
                </div>
            </motion.div>
					</AnimatePresence>
        </div>
        <div css={css`
          grid-column: 4 / 13;
          grid-row: 1 / 3;
          align-self: end;
          position: relative;
          ${mobile}{
            grid-column: 1 / 13;
            grid-row: 1;
            margin-bottom: 30px;
          }
        `}>
					<AnimatePresence exitBeforeEnter>
						<motion.div
							key={currentSlide}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						>
							<Slide 
								slide={slides[currentSlide]}	
								windowWidth={windowWidth}
								firstSlide={currentSlide === 0}
							/>
					</motion.div>
					</AnimatePresence>
          <div css={css`
            display: flex;
            justify-content: space-between;
            padding-top: 24px;
            ${mobile}{
              justify-content: flex-start;
            }
          `}>
            <button
              onClick={() => prevSlide()}
              className="h6"
              css={css`${mobile}{
                margin-right: 56px;
                padding: 4px;
              }`}
            >
              <Larr css={css`
                height: 16px;
                width: 16px;
                ${arrowCss}
                ${mobile}{
                  height: 11px;
                  width: 11px;
                  &:hover{
                    transform: none;
                  }
                }
              `}/>
            </button>
            <button
              onClick={() => nextSlide()}
              className="h6"
              css={css`${mobile}{padding: 4px;}`}
            >
              <Rarr css={css`
                height: 16px;
                width: 16px;
                ${arrowCss}
                ${mobile}{
                  height: 11px;
                  width: 11px;
                  &:hover{
                    transform: none;
                  }
                }
              `}/>
            </button>
          </div>
        </div>
      </Section>
    )
}


export default SliderBlock